import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
  Fade,
  LinearProgress,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import {
  ChecklistItem,
  getChecklistItems,
  getCompletionPercentage,
  getNextItem,
} from '@/utils/checklist';
import { getFirstName } from '@/utils/user';

import LoadingState from '@/components/LoadingState';
import TerriLottie from '@/components/lottie/TerriLottie';

import ChecklistStep from './checklist/ChecklistStep';

const ChecklistPage: React.FC = () => {
  const { onboardingProgress, isFetching, refetch } = useOnboardingProgress();
  const { user } = useAuth();
  const theme = useTheme();
  const { selectedPropertyLocation, isSingleFamilyLocation } = usePropertyManager();
  const [expandedItem, setExpandedItem] = useState<ChecklistItem | null>(null);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checklistItems = useMemo(
    () =>
      getChecklistItems({
        isSingleFamilyLocation,
        onboardingProgress,
        selectedPropertyLocation,
        user,
      }),
    [isSingleFamilyLocation, onboardingProgress, selectedPropertyLocation, user]
  );

  const nextItem = useMemo(() => getNextItem(checklistItems), [checklistItems]);
  const completionPercentage = useMemo(
    () => getCompletionPercentage(checklistItems),
    [checklistItems]
  );

  // Auto-expand the active step
  useEffect(() => {
    if (nextItem) {
      setExpandedItem(nextItem);
    }
  }, [nextItem]);

  const onToggleExpand = useCallback((item: ChecklistItem) => {
    setExpandedItem(_expandedItem => (_expandedItem?.title === item.title ? null : item));
  }, []);

  return (
    <Stack direction="column" spacing={3} sx={{ flex: '1 1 100%' }}>
      <DashboardHeader
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h5">{`Welcome, ${getFirstName(user)}`}</Typography>
            <TerriLottie size={32} sx={{ mt: -0.5 }} />
          </Box>
        }
        size="small"
      />

      {isFetching || !user ? (
        <LoadingState />
      ) : (
        <Card>
          <CardHeader
            title={
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="subtitle1">Setting Up Your Property</Typography>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    bgcolor: 'transparent',
                    borderRadius: '12px',
                    px: 1.5,
                    py: 0.5,
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Typography variant="body2">
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      component="span"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {completionPercentage}%
                    </Typography>{' '}
                    Complete
                  </Typography>
                </Box>
              </Stack>
            }
            sx={{
              borderBottom: '1px solid',
              borderColor: 'divider',
              pr: 4,
              pl: 3,
            }}
          />
          <LinearProgress
            variant="determinate"
            value={completionPercentage}
            sx={{
              height: 4,
              borderRadius: 0,
              '& .MuiLinearProgress-bar': {
                transition: 'transform 1s ease-in-out',
              },
            }}
          />
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Complete these steps to fully set up your property management system. Each step
                unlocks new features to help you manage your properties more effectively.
              </Typography>
            </Box>

            <Stack direction="column" spacing={0.5}>
              {checklistItems.map((item, index) => (
                <ChecklistStep
                  key={item.title}
                  item={item}
                  index={index}
                  isExpanded={expandedItem?.title === item.title}
                  isNext={item === nextItem}
                  onToggleExpand={onToggleExpand}
                />
              ))}
            </Stack>

            {completionPercentage >= 75 && completionPercentage < 100 && (
              <Fade in timeout={1000}>
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'rgba(84, 214, 44, 0.12)',
                    color: 'success.dark',
                    border: `1px solid ${theme.palette.success.light}`,
                  }}
                >
                  <Typography variant="subtitle2">Almost there!</Typography>
                  <Typography variant="body2">
                    You're making great progress! Just a few more steps to fully set up your
                    property management system.
                  </Typography>
                </Box>
              </Fade>
            )}

            {completionPercentage === 100 && (
              <Fade in timeout={1000}>
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'rgba(84, 214, 44, 0.12)',
                    color: 'success.dark',
                    border: `1px solid ${theme.palette.success.light}`,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h6">Congratulations! 🎉</Typography>
                  <Typography variant="body2">
                    You've completed all setup steps. Your property management system is now ready!
                  </Typography>
                </Box>
              </Fade>
            )}
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export default ChecklistPage;
