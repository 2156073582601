import { Button, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { useGetAllBuildings } from '@/api/endpoints/building/useGetAllBuildings';
import {
  ApiResponse_GetAll_Tenant,
  useGetAllTenants,
} from '@/api/endpoints/tenant/useGetAllTenants';
import { useGetAllUnits } from '@/api/endpoints/unit/useGetAllUnits';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatFullDate } from '@/utils/date';
import { formatPhoneNumber } from '@/utils/phone';
import { getResolvedRoutePath } from '@/utils/router';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import InviteTenantModal from '@/components/tenant/InviteTenantModal';
import TenantCreateModal from '@/components/tenant/TenantCreateModal';
import TenantDeleteModal from '@/components/tenant/TenantDeleteModal';
import TenantLeaseGeneratorModal from '@/components/tenant/TenantLeaseGeneratorModal';
import TenantUpdateModal from '@/components/tenant/TenantUpdateModal';

const UnitViewTenantListSubpage: React.FC = () => {
  const { id } = useParams<ExtractParams<PMPRoutes.unitView>>();
  const routeParams = useParams<ExtractParams<PMPRoutes.propertyLocationViewTenantList>>();
  const { locationId } = routeParams;
  const location = useLocation();
  const { isSingleFamilyLocation } = usePropertyManager();

  const [isTenantCreateModalOpen, setIsTenantCreateModalOpen] = useState(false);
  const [isTenantDeleteModalOpen, setIsTenantDeleteModalOpen] = useState(false);
  const [isTenantUpdateModalOpen, setIsTenantUpdateModalOpen] = useState(false);
  const [isTenantLeaseModalOpen, setIsTenantLeaseModalOpen] = useState(false);
  const [isInviteTenantModalOpen, setIsInviteTenantModalOpen] = useState(false);

  const [tenantToUpdate, setTenantToUpdate] = useState<ApiResponse_GetAll_Tenant[0] | null>(null);
  const [tenantToDelete, setTenantToDelete] = useState<ApiResponse_GetAll_Tenant[0] | null>(null);
  const [tenantForLease, setTenantForLease] = useState<ApiResponse_GetAll_Tenant[0] | null>(null);
  const [unitId, setUnitId] = useState<number | undefined>(id ? Number(id) : undefined);

  // For single family homes, get the first unit ID if not provided
  const getAllBuildingsQuery = useGetAllBuildings(
    {
      location_id: Number(locationId),
    },
    !!locationId && isSingleFamilyLocation && !unitId
  );

  const getAllUnitsQuery = useGetAllUnits(
    {
      buildingId: getAllBuildingsQuery.data?.[0]?.id,
    },
    !!getAllBuildingsQuery.data?.[0]?.id && isSingleFamilyLocation && !unitId
  );

  // Set the unit ID when we get it for single family homes
  useEffect(() => {
    if (isSingleFamilyLocation && !unitId && getAllUnitsQuery.data?.[0]?.id) {
      setUnitId(getAllUnitsQuery.data[0].id);
    }
  }, [isSingleFamilyLocation, unitId, getAllUnitsQuery.data]);

  const getAllTenantsQuery = useGetAllTenants({
    unit_id: unitId,
    ...(locationId && { location_id: Number(locationId) }),
  });

  const onEditTenantClick = useCallback((tenant: ApiResponse_GetAll_Tenant[0]) => {
    setTenantToUpdate(tenant);
    setIsTenantUpdateModalOpen(true);
  }, []);

  const onDeleteTenantClick = useCallback((tenant: ApiResponse_GetAll_Tenant[0]) => {
    setTenantToDelete(tenant);
    setIsTenantDeleteModalOpen(true);
  }, []);

  // const onGenerateLeaseClick = useCallback((tenant: ApiResponse_GetAll_Tenant[0]) => {
  //   setTenantForLease(tenant);
  //   setIsTenantLeaseModalOpen(true);
  // }, []);

  // Auto-open tenant create modal if URL has openAddTenant=true
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const shouldOpenAddTenant = searchParams.get('openAddTenant') === 'true';
    const shouldOpenInviteTenant = searchParams.get('openInviteTenant') === 'true';

    if (shouldOpenAddTenant) {
      setIsTenantCreateModalOpen(true);
    }

    // Auto-open invite tenant modal if URL has openInviteTenant=true
    if (shouldOpenInviteTenant) {
      setIsInviteTenantModalOpen(true);
    }
  }, [location.search, locationId, unitId]);

  const columns: GridColDef<ApiResponse_GetAll_Tenant[0]>[] = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Tenant[0]>) => (
          <Link href={getResolvedRoutePath(PMPRoutes.tenantView, { id: String(params.id) })}>
            {params.value}
          </Link>
        ),
      },
      {
        field: 'contacts',
        headerName: 'Contact Info',
        flex: 1,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Tenant[0]>) => (
          <Stack direction="column" spacing={1.25} sx={{ display: 'flex', flex: 1 }}>
            {params.row.contacts.map(contact => (
              <Stack direction="column" spacing={0.25} key={contact.id}>
                {!!contact.email && <Typography variant="body4">{contact.email}</Typography>}

                {!!contact.phone && (
                  <Typography variant="body4">{formatPhoneNumber(contact.phone)}</Typography>
                )}
              </Stack>
            ))}
          </Stack>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        valueFormatter: (value: string) => value || '',
      },
      {
        field: 'created_at',
        headerName: 'Date Onboarded',
        width: 180,
        valueFormatter: (value: ApiResponse_GetAll_Tenant[0]['created_at']) =>
          formatFullDate(new Date(value || '')),
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        type: 'actions',
        width: 50,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_Tenant[0]>) => (
          <RowActionsMenu
            items={[
              {
                label: 'Edit',
                onClick: () => onEditTenantClick(params.row),
              },
              // {
              //   label: 'Generate Lease',
              //   onClick: () => onGenerateLeaseClick(params.row),
              // },
              {
                label: 'Delete',
                onClick: () => onDeleteTenantClick(params.row),
              },
            ]}
          />
        ),
      },
    ],
    [onEditTenantClick, /* onGenerateLeaseClick, */ onDeleteTenantClick]
  );

  const onAddTenantClick = useCallback(() => {
    setIsTenantCreateModalOpen(true);
  }, []);

  const UnitViewTenantListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Tenant" onAddEntityClick={onAddTenantClick} />,
    [onAddTenantClick]
  );

  return (
    <Stack direction="column" spacing={2} sx={{ flex: '1 1 100%', position: 'relative' }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignSelf: 'flex-end',
          right: { sm: 0 },
        }}
      >
        <Button
          color="primary"
          variant="contained"
          size="medium"
          startIcon={<PlusIcon />}
          onClick={onAddTenantClick}
        >
          Add Tenant
        </Button>
      </Stack>

      <DataGrid
        rows={getAllTenantsQuery.data ?? []}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableEval
        disableRowSelectionOnClick
        loading={getAllTenantsQuery.isLoading}
        slots={{
          noRowsOverlay: UnitViewTenantListNoRowsOverlay,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sortingMode="server"
        getRowHeight={() => 'auto'}
      />

      {isTenantCreateModalOpen && unitId && (
        <TenantCreateModal
          onClose={() => setIsTenantCreateModalOpen(false)}
          unitId={unitId}
          hasExistingTenants={(getAllTenantsQuery.data ?? []).length > 0}
        />
      )}

      {isTenantUpdateModalOpen && !!tenantToUpdate && (
        <TenantUpdateModal
          onClose={() => {
            setIsTenantUpdateModalOpen(false);
            setTenantToUpdate(null);
          }}
          tenant={tenantToUpdate}
        />
      )}

      {isTenantDeleteModalOpen && !!tenantToDelete && (
        <TenantDeleteModal
          onClose={() => {
            setIsTenantDeleteModalOpen(false);
            setTenantToDelete(null);
          }}
          tenant={tenantToDelete}
        />
      )}

      {isTenantLeaseModalOpen && !!tenantForLease && (
        <TenantLeaseGeneratorModal
          onClose={() => {
            setIsTenantLeaseModalOpen(false);
            setTenantForLease(null);
          }}
          tenant={tenantForLease}
        />
      )}

      {/* Open the invite modal when flag is set, regardless of tenant count */}
      {isInviteTenantModalOpen && locationId && (
        <InviteTenantModal
          locationId={Number(locationId)}
          unitId={unitId}
          onClose={() => {
            setIsInviteTenantModalOpen(false);
            // Clear the URL parameter
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('openInviteTenant');
            window.history.replaceState({}, '', newUrl.toString());
          }}
          onTenantsInvited={() => {
            getAllTenantsQuery.refetch();
            setIsInviteTenantModalOpen(false);
          }}
        />
      )}
    </Stack>
  );
};

export default UnitViewTenantListSubpage;
